
<!-- src/components/TestDetail.vue -->
<template>
  <div class="container">
    <div class="background" :style="{ backgroundImage: 'url(' + backgroundUrl + ')' }"></div>
    <div class="overlay-detail">
      <div class="title">{{ title }}</div>
      <button class="pulse-button" @click="startTest(id)">Start</button>
    </div>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
// import GoogleAdSides from './GoogleAdSides.vue';

export default {
    name: 'TestDetail',
    components: {
      // GoogleAdSides
    },
    setup() {
        const route = useRoute();
        const id = ref(null);
        const title = ref(null);
        const backgroundUrl = ref(null);
        onMounted(() => {
            id.value = route.params.id;
            backgroundUrl.value = require('@/assets/image/' + route.params.id + '-100x200.png')
            var tests = {
                'life' :"Give your lifestyle a try?",
                'lifestyle' :"Test your lifestyle guru score?",
                'humor' :"Humor index?",
                'strong' :"Strength rating?",
                'sexy' :"Sexiness index?",
                'firstlove' :"Getting back together with your first love?",
                'truelove' :"Probability of meeting true love?",
                'discipline' :"degree of self-discipline?",
                'fruit' :"What kind of fruit are you?",
                'mbti' :"MBTI",
                'money' :"Your ability to make money?",
                'personality' :"personality test",
                'boredom' :"boredom level",
                'fashion' :"Test Your Fashion Index?",
                'mentalage' :"Test your mental age?",
                'emotional' :"Are you emotional or rational?",
                'personalitycolor' :"Test Personality Colors",
                'soul' :"Testing the Gender of the Soul",
                //8-16新增
                'workcity' :"What city are you suited to work in?",
                'SAS' :"SAS Self-Assessment Scale for Anxiety?",
                'lol' :"Love or like?",
                'stocks' :"Test to see if you have the talent to speculate on stocks Can you become a qualified investor?",
                'bestyou': 'What are you best at?',
                'making1': 'decision-making style Ⅰ',
                'making2': 'decision-making style ⅠⅠ', 
                'animal1': 'What kind of animal personality Ⅰ', 
                'animal2': 'What kind of animal personality ⅠⅠ',
                'dark': 'The dark factor of personality',
            }
            title.value = tests[id.value]
        });
        return {
            id,
            title,
            backgroundUrl,
        };
    },
    methods: {
        startTest(title) {
            this.$router.push('/question?id='+title);
        },
    },
};
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center; /* 水平居中对齐左右两个 div */
  align-items: center; /* 垂直居中对齐 */
  width: 100%;
  height: 100vh; /* 使容器占满整个视口高度 */
  overflow: hidden;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.overlay-detail {
  position: absolute;
  top: 50%;
  width: 22em;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 25px 30px;
  border-radius: 5px;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.pulse-button {
  background-color: #ff4081;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  cursor: pointer;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.detail-ad {
  position: absolute;
}
.detail-left, .detail-right {
  position: relative;
  width: 28%; /* 给左侧和右侧 div 设定宽度，可以根据需要调整 */
  height: 60%; /* 高度设定为80%，你可以根据实际情况调整 */
  background-size: cover;
  background-position: center;
}

.detail-left {
  margin-right:400px;
}

</style>
